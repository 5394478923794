import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import PropTypes from "prop-types";

const Corousal = ({ data, currentIndex, show, setShow, setCurrentIndex }) => {
  let cancelBubbling = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    setShow(true);
  };
  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } z-10 mt-4 md:mt-2 bg-custom-teal-light absolute inset-0 flex flex-col justify-center items-center`}
      onClick={() => setShow(false)}>
      <div>
        <div className="flex items-center justify-center">
          <button
            className={`${
              currentIndex === 0 && "opacity-70"
            } w-12 h-12 mr-8 md:mr-4 duration-150`}
            onClick={(e) => {
              if (currentIndex != 0) {
                setCurrentIndex((prev) => prev - 1);
              }
              cancelBubbling(e);
            }}>
            <StaticImage
              src="../../assets/images/home/leftArrow.svg"
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Previous"
            />
          </button>
          <div className="w-128 md:w-3/5 " onClick={(e) => cancelBubbling(e)}>
            <GatsbyImage
              image={getImage(data[currentIndex].image)}
              alt={data[currentIndex].title}
              className="w-full h-96 md:h-64"
            />
            <div className="bg-custom-teal text-white p-2">
              {data[currentIndex].info}
            </div>
          </div>
          <button
            className={`${
              currentIndex === data.length - 1 && "opacity-70"
            } w-12 h-12 ml-8 md:ml-4 duration-150`}
            onClick={(e) => {
              if (currentIndex != data.length - 1) {
                setCurrentIndex((prev) => prev + 1);
              }
              cancelBubbling(e);
            }}>
            <StaticImage
              src="../../assets/images/home/rightArrow.svg"
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Next"
            />
          </button>
        </div>
        <div
          className="flex flex-row mt-4 pl-4 pr-4 w-128 md:w-3/5 m-auto justify-center"
          onClick={(e) => cancelBubbling(e)}>
          {data
            .slice(currentIndex + 1, Math.min(currentIndex + 1 + 5, data.length))
            .map((val, i) => (
              <GatsbyImage
                className={`w-20 h-20 mx-2 ${i > 1 && "esm:hidden"} ${
                  i > 2 && "sm:hidden"
                } ${i > 3 && "md:hidden"}`}
                image={getImage(val.image)}
                alt={val.title}
                key={i}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

Corousal.propTypes = {
  data: PropTypes.array,
  currentIndex: PropTypes.number,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setCurrentIndex: PropTypes.func
};

export default Corousal;
