import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import Corousal from "./corousal";

const ProjectCard = ({ work, index, onClick }) => {
  let { image, title, info, city } = work;
  return (
    <div
      className={`max-w-[18rem] w-[22%] md:w-[45%] m-4 md:m-0 ${
        index % 2 === 0 ? "md:mr-2" : "md:ml-2"
      } md:mb-4 relative`}
      onClick={onClick}>
      <div>
        <GatsbyImage
          alt={title}
          image={getImage(image)}
          className="w-full h-64 md:h-40"
        />
      </div>
      <button
        className="bg-custom-yellow-light text-custom-teal-head absolute flex items-end w-full text-left pl-2 inset-0 
      opacity-0 hover:opacity-100 md:focus:opacity-100 duration-300">
        <h3 className="font-OswaldRegular uppercase">{title}</h3>
      </button>
    </div>
  );
};

const Projects = () => {
  const [showCorousal, setShowCorousal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="relative">
      <div className="pt-16 p-8">
        <h1 className="text-custom-teal-head text-center uppercase font-OswaldRegular">
          Projects
        </h1>
        <p className="text-custom-teal-body text-center text-[17.33px] max-w-[36rem] m-auto pb-10">
          Metal is versatile, ﬂuid and can be moulded into inﬁnite possibilities. For
          us, ceilings and facades provide a canvas to every space - they don't just
          add personality & ﬁnesse but also create a safe haven.
        </p>
        <div>
          <StaticQuery
            query={graphql`
              {
                allHomeJson {
                  nodes {
                    works {
                      image {
                        childImageSharp {
                          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                        }
                      }
                      title
                      info
                      city
                    }
                  }
                }
              }
            `}
            render={({ allHomeJson: { nodes } }) => {
              const works = nodes[0].works;
              return (
                <div className="max-w-[96rem] flex flex-row flex-wrap justify-center w-full m-auto">
                  <Corousal
                    data={works}
                    currentIndex={currentIndex}
                    show={showCorousal}
                    setShow={setShowCorousal}
                    setCurrentIndex={setCurrentIndex}
                  />
                  {works.map((work, i) => (
                    <ProjectCard
                      work={work}
                      key={i}
                      index={i}
                      onClick={() => {
                        setShowCorousal(true);
                        setCurrentIndex(i);
                      }}
                    />
                  ))}
                </div>
              );
            }}
          />
        </div>
        <button className="border-2 border-custom-teal p-2 m-auto text-custom-teal mt-8 mb-16 font-LatoBold hidden md:block">
          View More
        </button>
      </div>
    </div>
  );
};

export default Projects;
